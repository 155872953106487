$(function() {
  var not_vacancy_form = $('.placement_kinds').length === 0;
  var not_inactive = $('.cp_vacancies_list').length === 0;
  if (not_vacancy_form && not_inactive) return;

  $('body').on('click', '.placement_kind', function() {
    if (!$(this).hasClass('no-js-select')) {
      $('.placement_kind .control .button').removeClass('btn-green').removeClass('btn-big-grey').addClass('btn-big-grey').text('Выбрать');
      $('.placement_kind').removeClass('active');
      $('.placement_kind input[type="radio"]').prop('checked', false);
      $(this).addClass('active');
      $(this).find('.control .button').addClass('btn-green').removeClass('btn-big-grey').text('Выбрано');
      var kind = $(this).find('input[type="radio"]').prop('checked', true);

      var autorenewBlock = kind.closest('.js-vacancy-form').find('.js-vacancy-autorenew');

      if (kind.val() === 'standard_plus') {
        autorenewBlock.find('.standard_plus').not('.standard').removeClass('hidden');
        autorenewBlock.find('.standard').not('.standard_plus').addClass('hidden');
        autorenewBlock.find('input[name="vacancy[autorenew]"]').prop('checked', true);
        autorenewBlock.find('.standard:not(.standard_plus) input[name="vacancy[autorenew]"]').prop('disabled', true);
        autorenewBlock.find('.standard_plus input[name="vacancy[autorenew]"]').prop('disabled', false);
      } else {
        autorenewBlock.find('.standard_plus').not('.standard').addClass('hidden');
        autorenewBlock.find('.standard').not('.standard_plus').removeClass('hidden');
        autorenewBlock.find('input[name="vacancy[autorenew]"]').prop('checked', true);
        autorenewBlock.find('.standard_plus:not(.standard) input[name="vacancy[autorenew]"]').prop('disabled', 'disabled');
        autorenewBlock.find('.standard input[name="vacancy[autorenew]"]').prop('disabled', false);
      }
    }
  })
});

function processServiceSelect(el, inputId) {
  if ($(el).hasClass('active')) {
    $(el).parents('.vacancy_service').removeClass('active')
    $(el).removeClass('active');
    $(el).find('span.title').text('Выбрать');
    $(inputId).val(0);
  } else {
    $(el).parents('.vacancy_service').addClass('active')
    $(el).addClass('active');
    $(el).find('span.title').text('Выбрано');
    $(inputId).val(1);
  }
};

function bindServiceSelection() {
  $('body').on('click', '.js-mark_vacancy_button', function() {
    processServiceSelect(this, '.js-vacancy-form #vacancy_mark');
  })

  $('body').on('click', '.js-up_vacancy_button', function() {
    processServiceSelect(this, '.js-vacancy-form #vacancy_up');
  })

  $('body').on('click', '.js-banner_vacancy_button', function() {
    processServiceSelect(this, '.js-vacancy-form #vacancy_banner');
  })

  $('body').on('click', '.js-banner_pre290422_vacancy_button', function() {
    processServiceSelect(this, '.js-vacancy-form #vacancy_banner_pre290422');
  })

  $('body').on('click', '.js-select-stdplus', function() {
    $('.placement_kind.standard_plus').click();
  })

  $('body').on('click', '.js-select-std', function() {
    $('.placement_kind.standard').click();
  })
};

$(function() {
  bindServiceSelection();
});
