(function () {
  // skip logic if there is no form
  if ($('#hh-resume-load').length <= 0) return;

  const MESSAGES = { success: 'Резюме загружено' };

  const findHideable = function (container, selector) {
    const node = container.find(selector);

    return {
      show() {
        node.removeClass('hidden');
      },
      hide() {
        node.addClass('hidden');
      },
    };
  };

  const errors = (function (container) {
    const errorRef = {
      empty: container.find('.empty-link-error'),
      invalid: container.find('.invalid-link-error'),
      bad_link: container.find('.bad-link-error'),
      failed: container.find('.load-resume-error'),
      broken: container.find('.parser-brake-error'),
      broken_proxy: container.find('.parser-request-error'),
    };

    const hideAll = function () {
      $.each(errorRef, function (_, el) {
        el.addClass('hidden');
      });
    };

    const showSingle = function (key) {
      if (!errorRef[key]) return;

      hideAll();
      errorRef[key].removeClass('hidden');
    };

    return {
      hide: hideAll,
      show: showSingle,
    };
  })($('div.hh-resume-load'));

  const checkProgress = function () {
    const hhContainer = $('.hh-resume-load').filter('div');
    const loader = findHideable(hhContainer, 'div.loading');
    const form = findHideable(hhContainer, '.head, .body');

    $.post('/onboarding/hh_resume_progress')
      .done(function (resp) {
        if (
          ['failed', 'bad_link', 'broken', 'broken_proxy'].indexOf(
            resp.status,
          ) > -1
        ) {
          errors.show(resp.status);

          loader.hide();
          form.show();

          $(document).trigger('ajax:success');
        } else if (
          resp.status === 'success' ||
          resp.status === 'onboard_success'
        ) {
          window.helpers.notify(MESSAGES.success);
          setTimeout(function () {
            window.location.reload(true);
          }, 500);
        } else {
          setTimeout(checkProgress, 10e3);
        }
      })
      .fail(function () {
        errors.show('load');

        loader.hide();
        form.show();

        $(document).trigger('ajax:error');
      });
  };

  const loadResume = function (evt) {
    evt.preventDefault();

    const button = $(this).addClass('loading');
    const hhContainer = button.closest('div.hh-resume-load');
    const link = document.getElementById('hh-resume-link').value;

    if (link.trim().length <= 0) {
      errors.show('empty');

      setTimeout(function () {
        $(document).trigger('ajax:error');
      }, 100);
      return;
    }

    const loader = findHideable(hhContainer, 'div.loading');
    const form = findHideable(hhContainer, '.head, .body');
    errors.hide();

    $.post('/onboarding/fetch_hh_resume', { link: link.trim() })
      .done(function () {
        form.hide();
        loader.show();

        // start polling
        checkProgress();
      })
      .fail(function () {
        errors.show('invalid');

        setTimeout(function () {
          $(document).trigger('ajax:error');
        }, 100);
      });
  };

  document.addEventListener('DOMContentLoaded', function () {
    document
      .getElementById('hh-resume-load')
      .addEventListener('click', loadResume, false);
  });
})();
